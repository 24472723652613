import { v4 as uuidv4 } from 'uuid';

export function isNull(value: object) {
    return value === null || value === undefined;
}

export function isEmptyObject(param: object) {
    return isNull(param) ? true : Object.keys(param).length === 0 && param.constructor === Object;
}

export const createUuid = () => {
    return uuidv4().toString();
};

export function createFilledArray(start: number, end: number, format?: string) {
    const length = end - start + 1;
    const filledArray = Array.from({ length });

    const resultArray = filledArray.map((_, index) => {
        if (format) {
            return start + index + format;
        }

        return start + index;
    });
    return resultArray;
}
