import { z } from 'zod';
import { createUuid } from '@common/common.utils';

export const FileInfoSchema = z.object({
    id: z.number().nullable(),
    uuid: z.string(),
    name: z.string(),
    accept: z.string(),
    type: z.enum(['UPLOADED', 'NEW', 'DELETE']),
    originalFileName: z.string(),
    fileContentType: z.string(),
    fileSize: z.string(),
    fileUrl: z.string(),
    serverFileName: z.string(),
    thumbnail: z.string().nullable().optional(),
    fullPath: z.string().nullable().optional(),
    groupId: z.number().nullable().optional(),
    entityId: z.number().nullable().optional(),
    entityType: z.string().nullable().optional(),
    fileUploadType: z.string().nullable().optional(),
    file: z.instanceof(File).nullable().optional(),
});

export type FileInfoDTO = z.infer<typeof FileInfoSchema>;

export function fromFileInfos(files: FileList, name: string): FileInfoDTO[] {
    const fileInfoList: FileInfoDTO[] = [];
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileInfo = {
            id: null,
            uuid: createUuid(),
            name: name,
            accept: file.type,
            type: 'NEW' as const,
            originalFileName: file.name,
            fileContentType: file.type,
            fileSize: file.size.toString(),
            fileUrl: '',
            serverFileName: '',
            thumbnail: '',
            file: file,
        };
        fileInfoList.push(fileInfo);
    }
    return fileInfoList;
}

export function fromFileInfo(file: File, name: string): FileInfoDTO {
    return {
        id: null,
        uuid: createUuid(),
        name: name,
        accept: file.type,
        type: 'NEW' as const,
        originalFileName: file.name,
        fileContentType: file.type,
        fileSize: file.size.toString(),
        fileUrl: '',
        serverFileName: '',
        thumbnail: '',
        file: file,
    };
}
